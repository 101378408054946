import { SortingConfig } from 'common-ui-components/Table/sortingHook';
import { convertSortingConfig, RequestPaging, RequestSorting } from 'global/api/controller/utils/commonControllerUtils';
import { getRequest, HttpResponse, postRequest } from 'global/api/platformApiHelpers';
import App from 'global/lists/apps';
import { Atom } from 'model/Atom';
import Tenant from 'model/Tenant';
import {
  MasterFilters,
  MasterFiltersApiRequest,
  TimePeriod,
} from 'screens/platform/cross-platform-components/context/MasterFiltersContext/MasterFilters';
import convertMasterFilters
  from 'screens/platform/cross-platform-components/context/MasterFiltersContext/utils/MasterFiltersApiConversionUtils';
import { AtomsCounts } from 'screens/platform/cross-platform-components/context/platform/TotalAtomsCountsHook';

type ResponsePagesAmount = number | null;
interface AtomsResponse extends AtomsCount {
  atoms: Atom[];
  pagesAmount: ResponsePagesAmount;
}

export interface AtomsCount {
  allowedAtomsCount: number;
  totalAtomsCount: number;
  restrictedAtoms: {
    exists: boolean;
    count: number | null;
  };
}

type AtomsPageEndpoint = 'atom' | 'atom/summary';

export type RecentAtomsParticipants = 'MYSELF' | 'TEAM';

export interface SecondaryGroupFilter {
  internalPeopleIds?: string[];
  externalPeopleIds?: string[];
  organizationsIds?: string[];
  tag?: string;
}

export interface PagedAtomsRequest {
  currentFilters: MasterFilters;
  secondaryGroupFilter?: SecondaryGroupFilter;
  paging: RequestPaging;
  shouldGetPagesCount: boolean;
  sorting?: SortingConfig<string>;
}

export interface AtomsSummary {
  sessionId: string;
  summary: string;
  participants: {
    peopleIds: string[];
    departments: string[];
    organizationsIds: string[];
  };
  tags: string[];
  totalInteractions: number;
}

interface ExtendedAtom extends Atom {
  externalOrganizationsIds?: string[];
}

export type HighlightType =
  | 'PendingReviewHighlight'
  | 'BugHighlight'
  | 'LotsOfActionHighlight'
  | 'FomoHighlight'
  | 'ImportantFYIHighlight'
  | 'AllHandsOnDeckHighlight'
  | 'LongTimeNoSeeHighlight'
  | 'MegaMeetingHighlight'

export interface AtomHighlight {
  highlight: HighlightType;
  atom: ExtendedAtom;
  appId: App;
  permalink: string;
}

async function findAtomsPage<T extends AtomsResponse | AtomsSummary>(
  atomsRequest: Omit<PagedAtomsRequest, 'currentFilters' | 'sorting'> & {
    filters: MasterFiltersApiRequest;
    tenantId: number;
    sorting?: RequestSorting;
  },
  endpoint: AtomsPageEndpoint,
): HttpResponse<T> {
  const { tenantId, ...requestBody } = atomsRequest;
  return postRequest(endpoint, requestBody, {
    params: { tenantId },
  });
}

async function findAtomsPageByEndpoint<T extends AtomsResponse | AtomsSummary>(
  tenantId: Tenant['id'],
  pagedAtomsRequest: PagedAtomsRequest,
  endpoint: AtomsPageEndpoint,
): HttpResponse<T> {
  const { currentFilters, sorting, ...requestParams } = pagedAtomsRequest;
  const parsedMasterFilters = convertMasterFilters(currentFilters, true);
  return findAtomsPage({
    ...requestParams,
    tenantId,
    filters: parsedMasterFilters,
    sorting: sorting && convertSortingConfig(sorting),
  }, endpoint);
}

const AtomController = {
  async getAtoms(tenantId: Tenant['id'], pagedAtomsRequest: PagedAtomsRequest): HttpResponse<AtomsResponse> {
    return findAtomsPageByEndpoint<AtomsResponse>(tenantId, pagedAtomsRequest, 'atom');
  },

  async getAtomsSummary(tenantId: Tenant['id'], pagedAtomsRequest: PagedAtomsRequest): HttpResponse<AtomsSummary> {
    return findAtomsPageByEndpoint<AtomsSummary>(tenantId, pagedAtomsRequest, 'atom/summary');
  },

  async getLatestAtoms(
    tenantId: Tenant['id'],
    { currentFilters, timeRange }: { currentFilters: MasterFilters; timeRange: TimePeriod<'seconds'> },
  ): HttpResponse<AtomsResponse> {
    const filters = {
      ...convertMasterFilters(currentFilters, true),
      timePeriod: timeRange,
    };
    return findAtomsPage<AtomsResponse>({
      filters,
      paging: { skip: 0, limit: 100 },
      tenantId,
      shouldGetPagesCount: false,
    }, 'atom');
  },

  async getAtomsCounts(
    tenantId: Tenant['id'],
    currentFilters: MasterFilters,
  ): HttpResponse<AtomsCounts> {
    const parsedMasterFilters = convertMasterFilters(currentFilters, true);

    return postRequest('atom/count', { filters: parsedMasterFilters }, {
      params: { tenantId },
    });
  },

  async getRecentAtoms(
    tenantId: Tenant['id'],
    participants: RecentAtomsParticipants,
    limit: number,
  ): HttpResponse<Atom[]> {
    return getRequest('atom/recent', { tenantId, participants, limit });
  },

  async getAtomHighlights(
    tenantId: Tenant['id'],
  ): HttpResponse<AtomHighlight[]> {
    return getRequest('atom/highlights', { tenantId });
  },

  async getOrganizationAtomsCountByCommunicationType(
    tenantId: Tenant['id'],
    currentFilters: MasterFilters,
    organizationId: string,
  ): HttpResponse<{ byOrganizationsType: Record<string, Record<string, number>> }> {
    const parsedMasterFilters = convertMasterFilters(currentFilters, true);

    return postRequest('atom/count/organizationCommunicationType', { filters: parsedMasterFilters, organizationId }, {
      params: { tenantId },
    });
  },

};

export default AtomController;
